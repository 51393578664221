import { FormatDateInAmPm } from "src/reusable";

const userName = (name) => {
  return name
    ? name.name != undefined
      ? name.name.charAt(0).toUpperCase() + name.name.slice(1)
      : name.firstName != undefined
      ? name.firstName.charAt(0).toUpperCase() +
        name.firstName.slice(1) +
        " " +
        name.lastName.charAt(0).toUpperCase() +
        name.lastName.slice(1)
      : ""
    : "";
};

function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

function capitalizeFirstLetterEachWord(string) {

  console.log(string);

  if(string != undefined || string != null){
    let words = string.split(" ");
    if(words.length>1){
      words = words.map(element => element.charAt(0).toUpperCase()+element.slice(1));
      return words.join(" ");
    }else{
      return string.charAt(0).toUpperCase()+string.slice(1)
    }
  }else{
    return string;
  }
}

function roundAmount(amount) {
    return amount.toFixed(2);
}

const imageWithPath = (imageName, staticImage=process.env.REACT_APP_USER_IMAGE, fileType='') => {

	if(!imageName || typeof imageName === "undefined") {
		return staticImage;
	}

	return (fileType == 's3' || fileType == '') ? imageName : process.env.REACT_APP_MEDIA_URL + imageName;
}

const showToolTip = (e, elementName) => {
  elementName = elementName.toLowerCase().replaceAll(" ", "-");
  if (elementName == 'undefined') elementName = e.target?.title
  const title = e.target?.title;
  const tooltipTitle = document.querySelector(`.${elementName}-title`);

  document.querySelectorAll(`.mobile-tooltip:not(.${elementName}-title)`).forEach(e => e.remove());
  if (tooltipTitle == null) {
      e.target?.insertAdjacentHTML('beforeend', `<span class="${elementName}-title mobile-tooltip">${title}</span>`);
  } else {
     document.querySelector(`.${elementName}-title`).remove()
     document.querySelectorAll(`.${elementName}-title`).forEach(e => e.remove());
  }
}

function validateNumber(evt) {
  var e = evt || window.event;
  var key = e.keyCode || e.which;
  if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
  // numbers
  key >= 48 && key <= 57 ||
  // Numeric keypad
  ((key >= 96 && key <= 105) || key == 110) ||
  // Backspace and Tab and Enter
  key == 8 || key == 9 || key == 13 ||
  // Home and End
  key == 35 || key == 36 ||
  // left and right arrows
  key == 37 || key == 39 ||
  // Del and Ins
  key == 46 || key == 45) {
      // input is VALID
  }
  else {
      // input is INVALID
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
  }
}

const areaOfConcentrationMultiple = item => {
	return item !== undefined ? (item.length > 0 ? item.map((item) => `${item.title}`).join(" | ") : '') : '';
}

const stringTrim = (value,length=20) => {
  return value ? (value?.length > length ? value?.substr(0,length) +'...' : value?.substr(0,length)) : '';
}

function UnixTimeStampToDate(timestamp) {
  return new Date(timestamp * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' });
}

function UnixTimeStampToTime(timestamp) {
  return new Date(timestamp * 1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });

}

function roundOffAndDecimalFixValue(value) {
  // return (Math.round(value * 10) / 10).toFixed(1)
  let roundedValue = Math.round(value * 10);

  if (roundedValue % 10 === 0) {
      return roundedValue / 10;
  }

  return (roundedValue / 10).toFixed(1)
}

function ShowSocialMediaStatistics (value) {

  /**
   * 10^3 -> K
   * 10^6 -> M
   * 10^9 -> B
   */
  if (value < 0)
      return 0

  if (value < 1000)
      return value

  if (value < 1000000)
      return roundOffAndDecimalFixValue(value / 1000) + 'K'

  if (value < 1000000000)
      return roundOffAndDecimalFixValue(value / 1000000) + 'M'

  return roundOffAndDecimalFixValue(value / 1000000000) + 'B'
}

function dateOfBirthFormat (dob) {

  const date = new Date(dob);
  dob = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`
  return dob;
}

const periods = {
  month: 30 * 24 * 60 * 60 * 1000,
  week: 7 * 24 * 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000
};
const MessageTime = (timeCreated) => {
		let date = new Date(timeCreated)
        var diff = Date.now() - date;
		let currentdate = new Date();
        var previousDate = (currentdate.getDate() -1) == date.getDate() && currentdate.getMonth() == date.getMonth() && currentdate.getFullYear() == date.getFullYear()
      if (diff > (24 * 60 * 60 * 1000) && !previousDate) {
		    return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
		  } else if (previousDate) {
		    return 'Yesterday';
		  } else {
		  	return FormatDateInAmPm(timeCreated);
      }
    }
  
  const removeUnderScore = (phrase) => {

    if(phrase.length > 0 && phrase != null && phrase != undefined){
      let newPhrase = phrase.split("_");
      newPhrase = newPhrase.join(" ");
      return newPhrase;
    }


  }

  function getAddress(addressData) {
    let fullAddress = ''
    for (const key in addressData) {
        switch (key) {
            case "addressLine": {
                fullAddress += `${addressData[key]?.trim()}, `;
                break;
            }
            case "city": {
                fullAddress += `${addressData[key]}, `;
                break;
            }
            case "province": {
                fullAddress += `${addressData[key]}, `
                break;
            }
            case "country": {
                fullAddress = `${addressData[key]}, `
                break;
            }
            case "zip": {
                fullAddress += `${addressData[key]} `
                break;
            }
        }
    }
    return fullAddress
}

const checkForPluralLabel = (number, label) => {
  if (label) {
      return number === 0 ? `0 ${label}` : number > 1 ? `${number} ${label}s` : `${number} ${label}`
  }
  return "-";
}

export { userName, capitalizeFirstLetter, roundAmount, imageWithPath, showToolTip, validateNumber, areaOfConcentrationMultiple, stringTrim, UnixTimeStampToDate,
  UnixTimeStampToTime, ShowSocialMediaStatistics,dateOfBirthFormat, MessageTime, removeUnderScore,capitalizeFirstLetterEachWord, getAddress, checkForPluralLabel};
