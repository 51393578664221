import React from "react";
import CIcon from "@coreui/icons-react";
import { Roles } from "../config";

const _nav = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,

  },

  {
    _tag: "CSidebarNavDropdown",
    name: "Celebencers",
    icon: "cil-user-follow",
    permission: [Roles.UVREP],
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "List",
        to: "/uv-rep/celebencers",
        icon: "cil-people",
        permission: [Roles.UVREP],
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: "Transaction",
    icon: "cil-user-follow",
    permission: [Roles.UVREP],
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Transaction List",
        to: "/uv-rep/transaction-list",
        icon: "cil-people",
        permission: [Roles.UVREP],
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: "Users",
    icon: "cil-user-follow",
    permission: [Roles.ADMIN],
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Celebencers",
        to: "/admin/celebencers",
        icon: "cil-people",
        permission: [Roles.ADMIN],
        exact: false,        
      },
      {
        _tag: "CSidebarNavItem",
        name: "Fans",
        to: "/admin/customer",
        icon: "cil-people",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Merchants",
        to: "/admin/merchant",
        icon: "cil-people",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Referrers",
        to: "/admin/referrer",
        icon: "cil-people",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "UV Representatives",
        to: "/admin/uvRep",
        icon: "cil-people",
        permission: [Roles.ADMIN],
        exact: false,               
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Manage",
    icon: "cil-people",
    permission: [Roles.ADMIN],
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Areas Of Concentration",
        to: "/admin/areaofconcentration",
        icon: "cil-star",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Industries",
        to: "/admin/industries",
        icon: "cil-layers",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "MarketPlace Featured",
        to: "/admin/marketplace-recommendations",
        icon: "cil-list-rich",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Email Templates",
        to: "/admin/emails",
        icon: "cil-list-rich",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Mailing List",
        to: "/admin/emailList",
        icon: "cil-list-rich",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Request Limits",
        to: "/admin/requestLimit",
        icon: "cil-list-rich",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Commissions",
        to: "/admin/commission",
        icon: "cil-calculator",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Gallery",
        to: "/admin/gallery",
        icon: "cil-lightbulb",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "FAQ",
        to: "/admin/faq",
        icon: "cil-list-rich",
        permission: [Roles.ADMIN],
        exact: false,               
      },

      {
        _tag: "CSidebarNavItem",
        name: "Terms Of Services",
        to: "/admin/terms-of-services",
        icon: "cil-list-rich",
        permission: [Roles.ADMIN],
        exact: false,               
      },

    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Requests",
    icon: "cil-puzzle",
    permission: [Roles.ADMIN],
    _children: [

      {
        _tag: "CSidebarNavItem",
        name: "Shoutouts",
        to: "/admin/shoutout",
        icon: "cil-paper-plane",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Product Promotions",
        to: "/admin/promoted-orders",
        icon: "cil-pencil",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Live One On One",
        to: "/admin/liveOneOnOne",
        icon: "cil-puzzle",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      
      {
        _tag: "CSidebarNavItem",
        name: "Live Group Chats",
        to: "/admin/group-chat",
        icon: "cil-cursor",
        permission: [Roles.ADMIN],
        exact: false,               
      },

      {
        _tag: "CSidebarNavItem",
        name: "Premium Content",
        to: "/admin/performerSubscriber",
        icon: "cil-list-rich",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "Personal Appearances",
        to: "/admin/personalAppearance",
        icon: "cil-list-rich",
        permission: [Roles.ADMIN],
        exact: false,               
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: "Home Page",
    icon: "cil-home",
    permission: [Roles.ADMIN],
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Areas of concentration",
        to: "/admin/homePage",
        icon: "cil-layers",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      {
        _tag: "CSidebarNavItem",
        name: "UV News Feed",
        to: "/admin/uvnewsfeed",
        icon: "cil-cursor",
        permission: [Roles.ADMIN],
        exact: false,               
      },
    ],
  },


  {
    _tag: "CSidebarNavDropdown",
    name: "UV Store",
    icon: "cil-basket",
    permission: [Roles.ADMIN],
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Categories",
        to: "/admin/product-category",
        icon: "cil-layers",
        permission: [Roles.ADMIN],
        exact: false,               
      },
      

      {
        _tag: "CSidebarNavItem",
        name: "Recommendations",
        to: "/admin/product-recommendations",
        icon: "cil-list-rich",
        permission: [Roles.ADMIN],
        exact: false,               
      },

      {
        _tag: "CSidebarNavItem",
        name: "UV Store Orders",
        to: "/admin/storeOrder",
        icon: "cil-pencil",
        permission: [Roles.ADMIN],
        exact: false,               
      },

      {
        _tag: "CSidebarNavItem",
        name: "UV Store Products",
        to: "/admin/uvstore",
        icon: "cil-pencil",
        permission: [Roles.ADMIN],
        exact: false,               
      },

    ],
  },

  {
    _tag: "CSidebarNavItem",
    name: "Direct Messaging",
    to: "/admin/direct_message",
    icon: <svg xmlns="http://www.w3.org/2000/svg" className="c-sidebar-nav-icon" width="35" height="35" viewBox="0 0 30 30" fill="none">
      <g opacity="0.4">
        <path d="M11.25 14.25H10.5V15V16.9393L8.03033 14.4697L7.81066 14.25H7.5H3.75C2.78921 14.25 2 13.4608 2 12.5V6.25C2 5.28921 2.78921 4.5 3.75 4.5H13.75C14.7108 4.5 15.5 5.28921 15.5 6.25V12.5C15.5 13.4608 14.7108 14.25 13.75 14.25H11.25ZM19.5 22.5V21.75H18.75H16.25C15.2892 21.75 14.5 20.9608 14.5 20V18.201C17.3128 17.831 19.5 15.4101 19.5 12.5V12H26.25C27.2108 12 28 12.7892 28 13.75V20C28 20.9608 27.2108 21.75 26.25 21.75H22.5H22.1893L21.9697 21.9697L19.5 24.4393V22.5Z" stroke="white" stroke-width="1.5" />
      </g>
    </svg>

  },

  {
    _tag: "CSidebarNavItem",
    name: "Meet And Greet",
    to: "/admin/meet_and_greet",
    icon: "cil-cursor",
  },

  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Components']
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Base',
  //   route: '/base',
  //   icon: 'cil-puzzle',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Breadcrumb',
  //       to: '/base/breadcrumbs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Cards',
  //       to: '/base/cards',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Carousel',
  //       to: '/base/carousels',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Collapse',
  //       to: '/base/collapses',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Forms',
  //       to: '/base/forms',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Jumbotron',
  //       to: '/base/jumbotrons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'List group',
  //       to: '/base/list-groups',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Navs',
  //       to: '/base/navs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Navbars',
  //       to: '/base/navbars',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Pagination',
  //       to: '/base/paginations',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Popovers',
  //       to: '/base/popovers',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Progress',
  //       to: '/base/progress-bar',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Switches',
  //       to: '/base/switches',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tables',
  //       to: '/base/tables',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tabs',
  //       to: '/base/tabs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tooltips',
  //       to: '/base/tooltips',
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Buttons',
  //   route: '/buttons',
  //   icon: 'cil-cursor',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Buttons',
  //       to: '/buttons/buttons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Brand buttons',
  //       to: '/buttons/brand-buttons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Buttons groups',
  //       to: '/buttons/button-groups',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Dropdowns',
  //       to: '/buttons/button-dropdowns',
  //     }
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Charts',
  //   to: '/charts',
  //   icon: 'cil-chart-pie'
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Icons',
  //   route: '/icons',
  //   icon: 'cil-star',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'CoreUI Free',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'CoreUI Flags',
  //       to: '/icons/flags',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'CoreUI Brands',
  //       to: '/icons/brands',
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Notifications',
  //   route: '/notifications',
  //   icon: 'cil-bell',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Modal',
  //       to: '/notifications/modals',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Toaster',
  //       to: '/notifications/toaster'
  //     }
  //   ]
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: 'cil-calculator',
  //   badge: {
  //     color: 'info',
  //     text: 'NEW',
  //   },
  // },
  // {
  //   _tag: 'CSidebarNavDivider'
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Extras'],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Pages',
  //   route: '/pages',
  //   icon: 'cil-star',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Disabled',
  //   icon: 'cil-ban',
  //   badge: {
  //     color: 'secondary',
  //     text: 'NEW',
  //   },
  //   addLinkClass: 'c-disabled',
  //   'disabled': true
  // },
  // {
  //   _tag: 'CSidebarNavDivider',
  //   className: 'm-2'
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Labels']
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Label danger',
  //   to: '',
  //   icon: {
  //     name: 'cil-star',
  //     className: 'text-danger'
  //   },
  //   label: true
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Label info',
  //   to: '',
  //   icon: {
  //     name: 'cil-star',
  //     className: 'text-info'
  //   },
  //   label: true
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Label warning',
  //   to: '',
  //   icon: {
  //     name: 'cil-star',
  //     className: 'text-warning'
  //   },
  //   label: true
  // },
  // {
  //   _tag: 'CSidebarNavDivider',
  //   className: 'm-2'
  // }
];

export default _nav;
